import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Button, Spinner } from 'react-bootstrap';
import { FaEdit, FaTrash, FaCopy } from 'react-icons/fa';
import { format } from 'date-fns';
// Importe a função format para formatar datas
import SearchBar from '../components/FormComponents/SearchBar';
import FUNCS from '../utils';
const isDateTime = FUNCS.isDateTime;

const filtrarLinhasPorString = (dados, str) => {
  let dadoscp = JSON.parse(JSON.stringify(dados));
  if (!str) return dadoscp;
  const strMinuscula = str || ''; // Pré-processamento para case-insensitive
  const resultado = [];
  let valorString = '';
  for (let linha of dadoscp) {
    valorString = linha.integrity;
    if (valorString.includes(strMinuscula)) {
      resultado.push(linha); // Uma vez encontrada, passa para a próxima linha
    }
  }

  return resultado;
};

const consolidaIntegrity = (dados) => {
  let dadoscp = JSON.parse(JSON.stringify(dados));
  if (!dadoscp) return dadoscp;
  try {
    let consolidado = dadoscp;
    for (let i = 0; i < dadoscp.length; i++) {
      consolidado[i].integrity = '';
      for (let valor of Object.values(dadoscp[i])) {
        // Normalização para lidar com datas e números
        const valorString =
          typeof valor === 'string'
            ? valor.toLowerCase()
            : typeof valor === 'number'
            ? valor.toString()
            : ''; // Outros tipos são ignorados
        consolidado[i].integrity += valorString;
      }
    }
    return consolidado;
  } catch (e) {
    console.error(e);
  }
};

function Tabela({
  rawData,
  onEdit,
  onDelete,
  alterNames,
  isLoading,
  optionsLists,
  onCopy,
}) {
  const [termFilter, setTermFilter] = useState('');

  const allKeys = rawData && [
    ...new Set(rawData.flatMap((obj) => Object.keys(obj))),
  ];
  let uniqueKeys =
    allKeys &&
    allKeys.filter((item) => item !== 'created_by' && item !== 'updated_by');
  uniqueKeys =
    uniqueKeys && uniqueKeys.filter((i) => i === 'id' || alterNames[i]);

  const dados = consolidaIntegrity(rawData);
  const filteredDados = filtrarLinhasPorString(dados, termFilter);
  const dadosFiltrados = filteredDados;
  const handleSearch = (term) => {
    if (!term) return setTermFilter('');
    return setTermFilter(term);
  };

  return (
    <div className="d-flex bg-alert mh-100 w-100 h-100">
      <div className="d-flex flex-column p-1 pb-0 w-100 h-100 rounded shadow bg-white">
        <SearchBar searchTerm={termFilter} onSearch={handleSearch} />
        <div className="react-bootstrap-table">
          <div className="container p-1 pb-1 table-responsive w-100 h-100 rounded shadow bg-white">
            {isLoading ? ( // Exibe um spinner enquanto os dados estão carregando
              <div className="d-flex w-100 h-100 justify-content-center bg-white align-items-center vh-100">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Carregando...</span>
                </Spinner>
              </div>
            ) : (
              <Table striped bordered hover className="m-0 p-0 w-100 h-100">
                <thead>
                  <tr>
                    <td colSpan={uniqueKeys.length + 1}>
                      Termo Filtrado: {termFilter}
                    </td>
                  </tr>
                  <tr>
                    <th style={{ maxWidth: '10vw', wordWrap: 'break-word' }}>
                      Ações
                    </th>
                    {uniqueKeys &&
                      uniqueKeys.map((chave) => (
                        <th
                          key={chave}
                          style={{ maxWidth: '10vw', wordWrap: 'break-word' }}
                        >
                          {chave === 'id' || alterNames[chave] || null}
                        </th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  {dadosFiltrados &&
                    dadosFiltrados.map((dado) => (
                      <tr key={dado.id}>
                        <td
                          style={{ maxWidth: '10vw', wordWrap: 'break-word' }}
                        >
                          <Button
                            variant="outline-primary"
                            onClick={() => onEdit(dado)}
                            className="me-2"
                          >
                            <FaEdit />
                          </Button>
                          <Button
                            variant="outline-danger"
                            onClick={() => onDelete(dado.id)}
                          >
                            <FaTrash />
                          </Button>
                          <Button
                            variant="outline-info"
                            onClick={(e) => {
                              e.preventDefault();
                              let cdado = { ...dado };
                              delete cdado.id;
                              delete cdado.status;
                              onCopy(cdado);
                            }}
                          >
                            <FaCopy />
                          </Button>
                        </td>
                        {uniqueKeys.map((key) => {
                          if (
                            key !== 'created_at' &&
                            key !== 'updated_at' &&
                            key !== 'created_by' &&
                            key !== 'updated_by' &&
                            key.slice(-3) !== '_id'
                          ) {
                            let value = dado[key];
                            if (value && isDateTime(value)) {
                              value = value.slice(0, 10);
                            }
                            return (
                              <td className="m-0 p-0" key={key}>
                                {value || '-'}
                              </td>
                            );
                          } else if (key.slice(-3) === '_id') {
                            const dflt =
                              (optionsLists[key] &&
                                optionsLists[key].find(
                                  (k) => k.value === dado[key],
                                )) ||
                              null;

                            let value = dflt?.label || null;
                            if (isDateTime(value)) {
                              value = format(value, 'dd/MM/yyyy');
                            }
                            return (
                              <td className="m-0 p-0" key={key}>
                                {value || '-'}
                              </td>
                            );
                          }
                          return null;
                        })}
                      </tr>
                    ))}
                </tbody>
              </Table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

Tabela.propTypes = {
  rawData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  ).isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCopy: PropTypes.func.isRequired,
  alterNames: PropTypes.object,
  isLoading: PropTypes.bool,
  optionsLists: PropTypes.object,
};

export default Tabela;
